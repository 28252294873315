.price .items {
    padding: 40px;
    text-align: center;
  }
  .price h1 {
    font-size: 50px;
    margin: 20px 0;
    color: #000000;
  }
  
  .price h1 span {
    font-size: 20px;
    margin-right: 5px;
    color: #000;
  }
  .price p {
    padding-left: 10px;
    color: grey;
    margin: 40px 0;
  }
  /*--------------faq-------------*/
  .faq .container {
    max-width: 70%;
    border-radius: 5px;
  }
  .faq .box {
    background-color: #fff;
    margin-bottom: 20px;
  }
  .faq button {
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 15px 20px;
  }
  .faq h2 {
    font-weight: 500;
  }
  .faq p {
    padding: 20px;
  }
  .faq .accordion:hover {
    background-color: #810001;
    color: #fff;
  }
  
  #text {
    padding: 0 1rem;
  }
  #text h1{
    font-size: 25px;
    margin: 20px 0;
    text-transform: capitalize;
    font-weight: bold;
    color: rgb(0, 0, 0);
    margin-top: 0;
  }
  
  #testt:before {
    color: #810001;
    font-size: 40px;
  }
  a {
    color: #810001;
    text-decoration: none;
  }
  
  #text2 p{
  margin: 0;
  }
  .jobsList{
    padding: 20px;
    background-color: rgb(255, 255, 255);
    border: 1px solid #dddddd;
    border-radius: 2px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  }
    .jobtitle h1{
        font-size: 50px;
        margin: 20px 0;
        color: #000000;
    }
    .jobList p{
      margin:2px
    }
    .jobText {
        color: black;
    }
    .slick-list {margin: 0 -30px;}
  .slick-slide>div {padding: 6px 30px;}
  
  .jobsList h2 {
    font-size: 60px;
    font-family: sans-serif;
    display: flex;
    justify-content: center;
  }
  .jobsList h3 {
    /* font-size: 12px;
    background-color: rgb(255, 255, 255);
    display: flex;
    justify-content: center;
    border-radius: 5px;
    font-family: sans-serif; */
  }
  /*--------------faq-------------*/
  /* table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  th, td {
    border: 1px solid #dddddd;
    padding: 8px;
  }
  
  th {
    background-color: #f2f2f2;
  }
  
  tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  tr:hover {
    background-color: #f2f2f2;
  }
  
  tbody a {
    color: #810001;
    text-decoration: none;
  }
  
  tbody a:hover {
    text-decoration: underline;
  } */
/*
  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  th, td {
    border: 1px solid #000000; 
    padding: 8px;
  }
  
  th {
    background-color: #f2f2f2; 
  }
  
  tr:nth-child(even) {
    background-color: #f9f9f9; 
  }
  
  tr:hover {
    background-color: #e6e6e6; 
  }
  
  tbody a {
    color: #d21034; 
    text-decoration: none;
  }
  
  tbody a:hover {
    text-decoration: underline;
  }
  */
  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow effect */
    border-radius: 10px; /* Rounded corners */
    overflow: hidden; /* Prevents shadow from being clipped */
  }
  
  th, td {
    border: 1px solid #dddddd;
    padding: 12px; /* Increased padding for better spacing */
  }
  
  th {
    background-color: #f2f2f2;
    font-weight: bold; /* Make headers bold */
    text-align: left; /* Align text to the left */
  }
  
  tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  tr:hover {
    background-color: #f2f2f2;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Slight box-shadow on hover */
  }
  
  tbody a {
    color: #810001;
    text-decoration: none;
  }
  
  tbody a:hover {
    text-decoration: underline;
  }
  tr:hover {
    /* background-color: #f2f2f2; */
    transform: background-color(#f2f2f2); /* Add a slight scale effect on hover */
    transition: transform 0.2s ease-in-out; /* Smooth transition */
  }

  .pagination button{
    padding: 10px 30px;
    background-color: #fff;
    color: #810001;
    font-weight: 600;
    border: none;
    margin: 5px 10px 0 0;
    border-radius: 3px;
    cursor: pointer;
    box-shadow: -2px 5px 2px 0px rgb(0 0 0 / 9%);
    transition: 0.5s;
  }

  .pagination button:hover {
    background-color: #810001;
    color: #fff;
  }
  .pagination button.current-page {
    background-color: #810001;
    color: #fff;
  }
  .space3{
    padding: 25px;
  }
  /* .tips-container {
    display: flex;
  }
  .tips {
    flex: 1;
  }
  .tip {
    margin-bottom: 20px;
  }
  .money {
    flex: 1;
    background-image: url("saving_money3.jpg"); 
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  } */
  .tips-container {
    display: flex;
    align-items: flex-start; /* Aligns items to the start of the flex container */
    gap: 20px; /* This creates a gap between the tips and the money image */
    overflow: auto;

  }
  .tips {
    flex: 2; /* Assigns flex-grow to tips, making it take more space than the image */
    padding: 20px; /* Adds padding around the tips content */
    overflow: auto;

  }
  .money {
    display:flex;
    /*height: 800px;
    width: 300px; */
    background-image: url("saving_money3.jpg");
    /*background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    padding: 20px; 
    margin-top: 0px; */
    max-width: 100%;
    height: auto;
  }
  .tip {
    margin-bottom: 20px; /* Keeps the original margin for spacing between tips */
  }
  .tips h2 {
    padding-bottom: 30px;
  }
  .tip h3 {
    color: #333;
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .tip p {
    color: #666;
    font-size: 16px;
    line-height: 1.5;
  }
  