.newletter {
  background-color: #810001;
  padding: 50px 0;
  color: #fff;
}
.newletter .right,
.newletter .left {
  padding-right: 50px;
}
.newletter h1 {
  color: #fff;
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 15px;
}
.newletter .right {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.newletter input {
  width: 100%;
  padding: 20px;
  border: none;
  outline: none;
  height: 30px;
}
.newletter i {
  padding: 12px;
  color: #810001;
  background-color: #fff;
}
footer {
  background-color: #eeeeee;
}
footer .container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0px;
}
@media screen and (max-width: 768px) {
  footer .container {
    display: flex;
    flex-wrap: wrap;
  }
}
footer .logo span {
  color: #810001;
  font-size: 14px;
}
footer .logo p {
  color: grey;
  margin: 30px 0 15px 0;
}
footer .logo .icon {
  background-color: #810001;
  color: white;
  margin-right: 10px;
}
footer h3 {
  margin-bottom: 40px;
  font-weight: 500;
}
footer ul li {
  margin-bottom: 20px;
}
footer .link li::before {
  content: "⟶";
  color: #810001;
  margin-right: 5px;
}
footer .items {
  margin-bottom: 30px;
}
footer .items img {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  margin-right: 10px;
  object-fit: cover;
}
footer .items span,
footer .items i {
  font-size: 12px;
  color: #810001;
  margin-right: 5px;
  text-transform: capitalize;
}
footer h4 {
  font-weight: 400;
  margin-top: 5px;
}
footer .last ul li {
  display: flex;
  margin-bottom: 50px;
}
footer .last i {
  width: 60px;
  font-size: 20px;
  color: #810001;
  padding-right: 40px;
}
.legal {
  text-align: center;
  padding: 50px 0;
  color: grey;
  background-color: #e9e9e9;
}
.legal p {
  font-size: 15px;
}
.legal i {
  color: #810001;
}
