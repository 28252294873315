.price .items {
  padding: 40px;
  text-align: center;
}
.price h1 {
  font-size: 50px;
  margin: 20px 0;
  color: #000000;
}
.price h1 span {
  font-size: 20px;
  margin-right: 5px;
  color: #000;
}
.price p {
  padding-left: 10px;
  color: grey;
  margin: 40px 0;
}
/*--------------faq-------------*/
.faq .container {
  max-width: 70%;
  border-radius: 5px;
}
.faq .box {
  background-color: #fff;
  margin-bottom: 20px;
}
.faq button {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 15px 20px;
}
.faq h2 {
  font-weight: 500;
}
.faq p {
  padding: 20px;
}
.faq .accordion:hover {
  background-color: #810001;
  color: #fff;
}

#text {
  padding: 0 1rem;
}
#text h1{
  font-size: 25px;
  margin: 20px 0;
  text-transform: capitalize;
  font-weight: bold;
  color: rgb(0, 0, 0);
  margin-top: 0;
}

#testt:before {
  color: red;
  font-size: 40px;
}
a {
  color: #810001;
  text-decoration: none;
}

#text2 p{
margin: 0;
}

#buttonStyle {
  background: red;
  color: white;
  font-weight: 600;
  padding: 10px 20px;
  margin: 0 10px;
  border: none;
  cursor: pointer ;
  text-decoration: none ;
  border-radius: 3px;
  box-shadow: -2px 3px 9px 0px #8080758a;
  text-align: center;
  transition: 3s;
}


/* .servicecontent {
  max-width: 1100px;
  margin: 0 auto;
  padding: 20px;
} */

.servicecontent h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.servicecontent p {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 20px;
}

.servicecontent .content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.servicecontent .left {
  flex: 1;
  margin-right: 20px;
}

.servicecontent .left iframe {
  width: 100%;
  height: 315px;
}

.servicecontent #text {
  flex: 1;
}

.servicecontent .space {
  margin-bottom: 40px;
}

.servicecontent .button-container {
  display: flex;
  flex-wrap: wrap;
}

.servicecontent .button-container button {
  padding: 10px 20px;
  margin-right: 10px;
  margin-bottom: 10px;
  background-color: #810001;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.servicecontent .button-container button:hover {
  background-color: #9c1f00;
}

@media (max-width: 768px) {
  .servicecontent .content {
    flex-direction: column;
  }

  .servicecontent .left,
  .servicecontent #text {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .servicecontent .left iframe {
    height: 200px;
  }

  .servicecontent .button-container button {
    margin-right: 0;
    margin-bottom: 10px;
  }
}
